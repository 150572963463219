import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { setLastAggregatePageVisited } from '../redux/systemSlice'
import { DOC_TEMPLATES } from '../constants'
import UserDocs from '../Components/UserDocs/UserDocs'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import useNewDocument from '../hooks/useNewDocument'
import { Link, useHistory } from 'react-router-dom'
import { FEATURE_FLAGS } from '../hooks/featureFlags'
import useIsSubmit from '../hooks/useIsSubmit'
import Arrow from '../Components/Arrow'
import { useIsAuthenticated } from '../hooks/authenticated'
import useEnterApp from '../hooks/enterApp'
import Loader from '../Components/Loader'

export type templateType = {
	id: string
	title: string
	label?: string
	desc: string
	href?: string
}

export const useOptions = (): templateType[] => {
	const humanizerDetectorFF = useFeatureFlagVariantKey(FEATURE_FLAGS.HUMANIZER_DETECTOR_TOOLS)

	if (humanizerDetectorFF === 'test') {
		return [
			{
				id: DOC_TEMPLATES.Writer,
				title: 'AI Essay Writer',
				desc: 'Choose a topic, word count, tone, reading level, and more.',
			},
			{
				id: 'humanizer',
				title: 'AI Humanizer',
				desc: 'Paste in text and we will rewrite it to avoid AI detection.',
				href: '/humanizer',
			},
			{
				id: 'detector',
				title: 'AI Detector',
				desc: 'Paste in text and we run it through 8 AI detection models.',
				href: '/detector',
			},
			{
				id: DOC_TEMPLATES.Outline,
				title: 'AI Essay Outliner',
				desc: "Choose a topic and we'll generate an outline for you to complete.",
			},
		]
	} else {
		return [
			{
				id: DOC_TEMPLATES.Writer,
				title: 'AI Essay Writer',
				label: ' — lots of help',
				desc: 'Choose a topic, word count, tone, reading level, and more.',
			},
			{
				id: DOC_TEMPLATES.Outline,
				title: 'AI Essay Outliner',
				label: ' — some help',
				desc: "Choose a topic and we'll generate an outline for you to complete.",
			},
			{
				id: DOC_TEMPLATES.Blank,
				title: 'Blank Document',
				label: ' — little help',
				desc: 'Start yourself and use our autocomplete tool to guide you.',
			},
		]
	}
}

const pageName = 'doc'

function Doc() {
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const history = useHistory()
	const { createDoc } = useNewDocument()
	const options = useOptions()
	const isSubmit = useIsSubmit()
	const isAuthenticated = useIsAuthenticated()
	const { createUser } = useEnterApp({ history })

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/choose' }))
	}, [dispatch])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		if (!isAuthenticated) {
			createUser()
		}
	}, [isAuthenticated, createUser])

	const templateOptions = useMemo(() => {
		return (
			<div>
				{options.map((item, index) => {
					const desc =
						item.id === DOC_TEMPLATES.Writer ? 'Choose a topic, word count, writing style, and more.' : item.desc
					return (
						<Link
							className={`${
								isSubmit ? 'hover:bg-gray-200' : 'hover:bg-secondary'
							} cursor-pointer mb-10 py-2 px-3 flex justify-between items-center text-primary transition-colors group template-options`}
							to={item.href ?? '/new/' + item.id}
							key={'option' + index}
							onClick={() => {
								posthog.capture('template-chosen', { type: item.title })
							}}
						>
							<div className="flex flex-col gap-2">
								<h5 className="text-3xl">
									{item.title}
									{item.label && <span className={isSubmit ? 'text-gray-700' : 'fancy-font'}>{item.label}</span>}
								</h5>
								<p className="m-0 text-gray-700">{desc}</p>
							</div>
							<span
								className={`relative transition-all text-5xl opacity-0 group-hover:opacity-100 group-hover:right-0 right-5 ${
									isSubmit ? 'text-gray-500' : 'bottom-1'
								}`}
							>
								<Arrow fontSize={34} />
							</span>
						</Link>
					)
				})}
			</div>
		)
	}, [posthog, createDoc, options])

	if (!isAuthenticated) {
		return (
			<div className="flex justify-center items-center h-screen">
				<Loader />
			</div>
		)
	}

	return (
		<>
			<Helmet>
				<title>Choose an Option</title>
			</Helmet>
			<main id={pageName} className="px-2 lg:px-56 pt-16 max-w-[1400px] mx-auto pb-24">
				<div className="mb-6">
					<UserDocs />
				</div>
				<h5 className="md:text-4xl text-center my-3 text-3xl">Choose an option</h5>
				<hr className="border-t border-primary border-dotted mb-12" />
				{templateOptions}
			</main>
		</>
	)
}

export default Doc
