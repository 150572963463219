import { useSelector } from 'react-redux'
import { useAuth } from '../contexts/Auth'
import { selectPaddle, selectUser } from '../redux/systemSlice'
import { FEATURE_FLAGS, usePricing } from './featureFlags'
import { useFeatureFlagVariantKey } from 'posthog-js/react'

const useCheckout = () => {
	const usePaddle = useFeatureFlagVariantKey(FEATURE_FLAGS.PADDLE)
	const paddle = useSelector(selectPaddle)
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const pricing = usePricing()

	const checkout = async (annual: boolean) => {
		if (!user) return
		if (usePaddle === 'test' && paddle) {
			paddle.Checkout.open({
				items: [{ priceId: annual ? 'pri_01j9n6bx0sc9b3xf88v0s99fgr' : 'pri_01j9n6a04gmx5387g2zj0r70en', quantity: 1 }],
				customData: {
					user_id: user.id,
				},
				settings: {
					successUrl: window.location.href + `/?showModal=true${annual ? '&annual=true' : ''}`,
				},
			})
			return
		}
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				uid: currentUser.uid,
				humanizerUpgrade: false,
				price: annual ? pricing.stripePriceId.yearly ?? pricing.stripePriceId.monthly : pricing.stripePriceId.monthly,
				annual: annual,
				returnUrl: window.location.href,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/payments/checkout/', requestOptions)
			.then((res) => res.json())
			.then((response: { url: string }) => {
				if (response.url) {
					window.open(response.url, '_self')
				}
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return { checkout }
}

export default useCheckout
