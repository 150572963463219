import useIsSubmit from '../hooks/useIsSubmit'
import Editor from './Humanizer/editor'

const DetectorPage = () => {
	const isSubmit = useIsSubmit()
	return (
		<div className="flex flex-col gap-5 mt-20">
			<div className="flex flex-col">
				<div className="text-3xl font-semibold text-center">AI Detector</div>
				<div className="text-center text-gray-700">Scan for AI using 8 different detectors.</div>
			</div>
			<div className="flex justify-center z-10">
				<Editor detector />
			</div>
			{!isSubmit && (
				<div className="w-screen h-screen fixed bg-gradient-to-b from-white to-[#3b82f6] top-0 opacity-15" />
			)}
		</div>
	)
}

export default DetectorPage
