import React, { RefObject } from 'react'
import useIsSubmit from '../../hooks/useIsSubmit'

type buttonProps = {
	children: React.ReactNode
	type: 'primary' | 'secondary' | 'tertiary'
	emphasize?: boolean
	onClick?: (e?: any) => void
	onMouseDown?: (e?: any) => void
	disabled?: boolean
	className?: string
	htmlType?: 'button' | 'submit' | 'reset' | undefined
	style?: React.CSSProperties
	btnRef?: RefObject<HTMLButtonElement>
}

const Button = (props: buttonProps) => {
	const isSubmit = useIsSubmit()
	const buttonTypeClassNames = {
		primary: '',
		secondary: `py-1 px-3 ${isSubmit ? '' : 'hover:bg-secondary fancy-font uppercase'}`,
		tertiary: `border border-dotted border-primary ${
			isSubmit ? 'rounded px-5 py-1' : 'hover:bg-secondary uppercase px-5 py-0.5'
		}`,
	}
	const buttonClassNames = buttonTypeClassNames[props.type]
	const button = (
		<button
			ref={props.btnRef}
			onClick={() => {
				if (props.onClick && !props.disabled) {
					props.onClick()
				}
			}}
			onMouseDown={() => {
				if (props.onMouseDown && !props.disabled) {
					props.onMouseDown()
				}
			}}
			className={`
				cursor-pointer 
				${buttonClassNames} ${props.disabled ? 'cursor-not-allowed' : ''} ${props.className ? props.className : ''} ${
				props.emphasize
					? isSubmit
						? 'bg-[#0b6ade] hover:[#0859bd] text-white border border-gray-400 '
						: 'bg-secondary border border-primary border-dotted hover:bg-[#a5f0ee]'
					: ''
			}
				`}
			type={props.htmlType ?? 'button'}
			style={props.style}
		>
			{props.children}
		</button>
	)
	return button
}

export default Button
