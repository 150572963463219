import { useState } from 'react'
import LoginModal from './Login'
import Subscriptions from './Subscriptions'
import { useLocalStorage } from '../../helpers/utility'

const UpgradeModals = ({
	subscriptionModalOpen,
	loginModalOpen,
	setSubscriptionModalOpen,
	setLoginModalOpen,
	premiumPath,
	noMoreCredits = false,
}: {
	subscriptionModalOpen: boolean
	loginModalOpen: boolean
	setSubscriptionModalOpen: (open: boolean) => void
	setLoginModalOpen: (open: boolean) => void
	premiumPath?: boolean
	noMoreCredits?: boolean
}) => {
	const [annualPlan, setAnnualPlan] = useLocalStorage('annualPlan', false)
	return (
		<>
			<Subscriptions
				open={subscriptionModalOpen}
				closeModal={() => setSubscriptionModalOpen(false)}
				setLoginModal={(open: boolean) => {
					setLoginModalOpen(open)
				}}
				annualPlan={annualPlan}
				setAnnualPlan={setAnnualPlan}
				noMoreCredits={noMoreCredits}
			/>
			<LoginModal
				premiumPath={premiumPath}
				open={loginModalOpen}
				setModalOpen={(open: boolean) => {
					setLoginModalOpen(open)
				}}
				annualPlan={annualPlan}
			/>
		</>
	)
}

export default UpgradeModals
