import { FaCaretDown } from 'react-icons/fa6'
import Loader from '../Loader'
import Modal from './Modal'
import './_Detection.scss'
import Button from '../Buttons'
import { selectDocumentState } from '../../redux/docSlice'
import { useSelector } from 'react-redux'
import useWriteEssay from '../../hooks/useWriteEssay'
import { useDocId } from '../../hooks/docID'
import { usePostHog } from 'posthog-js/react'

const DetectionModal = ({
	open,
	closeModal,
	result,
	loading,
}: {
	open: boolean
	closeModal: () => void
	result: number | null
	loading: boolean
}) => {
	const posthog = usePostHog()
	const docId = useDocId()
	const documentState = useSelector((state) => selectDocumentState(state, docId))
	const { humanizeEssay } = useWriteEssay({ docID: docId })
	if (!loading && result === null) {
		return null
	}
	const score = (result ?? 0) * 100
	const isUndetectable = score < 50

	return (
		<Modal open={open} closeModal={closeModal} portalClassName="aiDetection">
			{loading || result === null ? (
				<div className="m-auto pb-20 pt-16">
					<Loader />
				</div>
			) : (
				<div className="flex flex-col gap-2">
					<div className="text-2xl font-semibold">AI Text Detector</div>
					<div className="text-gray-700">Detect AI-generated text in your document.</div>
					<div className="flex flex-col items-center bg-white px-3 pt-6 pb-5 rounded shadow">
						<div className="w-full h-3 flex justify-end border border-gray-400 rounded-full result">
							<div
								className="relative border-r border-gray-400 rounded-r-full bg-white"
								style={{ width: 100 - score + '%' }}
							>
								<div className="absolute left-[-9px] bottom-2 text-lg">
									<FaCaretDown />
								</div>
							</div>
						</div>
						<div className="w-full flex justify-between mt-2">
							<div className={`text-sm  ${isUndetectable ? 'font-semibold text-primary' : 'text-gray-700'}`}>Human</div>
							<div className={`text-sm  ${!isUndetectable ? 'font-semibold text-primary' : 'text-gray-700'}`}>AI</div>
						</div>
						<div className="flex flex-col gap-2 mt-2 items-center">
							<div className="px-3 py-1 rounded-full shadow border border-gray-400 text-sm">
								There's a <b>{Math.floor(score)}%</b> chance AI wrote this essay.
							</div>
							{isUndetectable && <div className="mt-3 font-semibold">Your essay is considered undetectable! 🎉</div>}
							{!isUndetectable && (
								<Button
									type="primary"
									className="mt-3 border py-2 px-8 border-gray-500 font-semibold hover:bg-gray-100 cursor-pointer"
									onClick={() => {
										humanizeEssay(documentState.content)
										closeModal()
										posthog.capture('rehumanize-essay')
									}}
								>
									Humanize
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
		</Modal>
	)
}

export default DetectionModal
