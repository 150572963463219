import { useEffect, useMemo } from 'react'
import './_WriterOptions.scss'
import { useDispatch, useSelector } from 'react-redux'
import { CONSTANTS, TYPE_OPTIONS, HUMANIZER_OPTIONS, DOC_TEMPLATES } from '../../constants'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import Button from '../Buttons'
import { isEqual } from 'lodash'
import { DocumentState, UserWriterSelections } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS, useFreeWordLimit } from '../../hooks/featureFlags'
import WritingStyleSelection from './WritingStyle'
import ReferencesSelection from './References'
import { FaLock, FaX } from 'react-icons/fa6'
import { Tooltip } from '@mui/material'
import { IoInformationCircleOutline } from 'react-icons/io5'
import useIsPremium from '../../hooks/useIsPremium'
import useIsSubmit from '../../hooks/useIsSubmit'

type Props = {
	type: string
	premiumUser: boolean
	formValid: boolean
	localUserWriterSelections: UserWriterSelections
	docID: string
	setSubscriptionModalOpen: (open: boolean) => void
}

function WriterOptions(props: Props) {
	const freeWordLimit = useFreeWordLimit()
	const dispatch = useDispatch()
	const stealthGptFF = useFeatureFlagVariantKey(FEATURE_FLAGS.STEALTH_GPT)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))
	const isPremium = useIsPremium()
	const showStealthGpt = stealthGptFF === 'stealth-checkbox' && isPremium
	const isSubmit = useIsSubmit()

	//Set redux if we have local storage for this document on component mount
	useEffect(() => {
		if (!isEqual(props.localUserWriterSelections, blankUserWriterSelections)) {
			dispatch(
				setUserWriterSelections({
					docID: props.docID,
					userWriterSelections: props.localUserWriterSelections,
				})
			)
		}
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const pageCount = useMemo(() => {
		const wordCount = documentState.userWriterSelections?.wordCount ?? freeWordLimit
		return (wordCount / 500).toFixed(1)
	}, [documentState.userWriterSelections?.wordCount])

	const checkbox = (
		<div
			className={`flex justify-between gap-2 mt-12 items-center cursor-pointer ${isPremium ? 'group' : 'opacity-50'}`}
			onClick={(e) => {
				if (!isPremium) {
					props.setSubscriptionModalOpen(true)
					return
				}
				dispatch(
					setUserWriterSelections({
						docID: props.docID,
						userWriterSelections: {
							humanizerIntensity: documentState.userWriterSelections?.humanizerIntensity === 'NONE' ? 'HIGH' : 'NONE',
						},
					})
				)
			}}
		>
			<div className="flex gap-3 items-center grow">
				<Tooltip title={isPremium ? '' : 'Upgrade to our premium plan to enable this feature.'} placement="top-start">
					<div className="md:text-2xl text-xl font-semibold">Humanize Text</div>
				</Tooltip>
				{!isPremium && (
					<Tooltip
						title="Transform AI-generated text to undetectable, human writing. Our humanizer is optimized to pass Turnitin's detection."
						placement="top-start"
					>
						<div>
							<IoInformationCircleOutline className="text-2xl" />
						</div>
					</Tooltip>
				)}
			</div>
			<div className="flex justify-center items-center p-2 border border-primary group-hover:bg-secondary">
				{isPremium ? (
					<FaX
						className={documentState.userWriterSelections?.humanizerIntensity === 'NONE' ? 'invisible' : 'visible'}
					/>
				) : (
					<Tooltip title="Upgrade to our premium plan to enable this feature." placement="top-end">
						<div>
							<FaLock />
						</div>
					</Tooltip>
				)}
			</div>
		</div>
	)

	return (
		<>
			<div id="writerOptions">
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<h4 className="sliderValue header-lg">
							<input
								onChange={(e) => {
									dispatch(
										setUserWriterSelections({
											docID: props.docID,
											userWriterSelections: { wordCount: parseInt(e.target.value) },
										})
									)
								}}
								onBlur={() => {
									const input = documentState.userWriterSelections?.wordCount ?? freeWordLimit
									if (input > CONSTANTS.WORD_COUNT_MAX) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MAX },
											})
										)
									} else if (input < CONSTANTS.WORD_COUNT_MIN) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MIN },
											})
										)
									}
								}}
								type="number"
								className="value"
								value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
								placeholder=""
							/>
							<span className="units fancy-font">
								words<span className="pageCount">, or ~{pageCount} pages</span>
							</span>
						</h4>
						<input
							type="range"
							min={CONSTANTS.WORD_COUNT_MIN}
							max={CONSTANTS.WORD_COUNT_MAX}
							step={50}
							className="slider"
							value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
							onChange={(e) => {
								dispatch(
									setUserWriterSelections({
										docID: props.docID,
										userWriterSelections: { wordCount: parseInt(e.target.value) },
									})
								)
							}}
						/>
						{isPremium && !showStealthGpt && (
							<div className="humanizerContainer">
								<div className="text-xl mb-3 font-semibold">Select humanizer intensity: </div>
								<div className="toneTypeList">
									{HUMANIZER_OPTIONS.map((str, index) => {
										return (
											<Button
												className={`w-full text-xl mx-3 ${
													str === documentState.userWriterSelections?.humanizerIntensity &&
													(isSubmit ? 'bg-gray-300' : 'bg-secondary')
												}`}
												key={str + index}
												type="secondary"
												onClick={(e) => {
													dispatch(
														setUserWriterSelections({
															docID: props.docID,
															userWriterSelections: { humanizerIntensity: str },
														})
													)
												}}
											>
												<span>{str}</span>
											</Button>
										)
									})}
								</div>
							</div>
						)}
						{showStealthGpt && checkbox}
					</>
				)}
				<div className="toneTypeContainer">
					<div className="text-xl mb-3 font-semibold">Select an essay type </div>
					<div className="toneTypeList">
						{TYPE_OPTIONS.map((str, index) => {
							return (
								<Button
									className={`toneTypeButton ${
										str === documentState.userWriterSelections?.type &&
										(isSubmit ? 'bg-tertiary text-white' : 'bg-secondary')
									}`}
									key={str + index}
									type="primary"
									onClick={(e) => {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { type: str },
											})
										)
									}}
								>
									<span>{str}</span>
								</Button>
							)
						})}
					</div>
				</div>
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<WritingStyleSelection docID={props.docID} />
						<ReferencesSelection docID={props.docID} />
					</>
				)}
			</div>
		</>
	)
}

export default WriterOptions
