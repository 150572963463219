import { Link } from 'react-router-dom'
import './_Post.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLastAggregatePageVisited } from '../redux/systemSlice'
import * as Sentry from '@sentry/react'
import { Helmet } from 'react-helmet-async'
import Footer from '../Components/Footer'
import Arrow from '../Components/Arrow'

type PostProps = {
	path: string
}

type PostType = {
	title: string
	content: string
}

function Post(props: PostProps) {
	const pageName = 'post'
	const [post, setPost] = useState<PostType>()
	const lastAggregatePageVisited = useSelector(selectLastAggregatePageVisited)

	useEffect(() => {
		fetch(process['env']['REACT_APP_API_ROOT'] + '/cms/get-example/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				path: props.path,
			}),
		})
			.then((res) => res.json())
			.then((data: PostType) => {
				setPost(data)
			})
			.catch((err) => Sentry.captureException(err))
	}, [props.path])

	return (
		<>
			{post && (
				<Helmet>
					<title>{post.title + ' - The Good AI'}</title>
					<meta
						name="description"
						content={`The Good AI is an AI essay writing and outlining site that write your essays in seconds using artificial intelligence. This page is about ${post.title}.`}
					/>
				</Helmet>
			)}
			<main id={pageName}>
				<div className="paddedContainer backContainer">
					<Link to={lastAggregatePageVisited}>
						<span className="back">
							<Arrow />
						</span>
					</Link>
				</div>
				<div className="px-4 lg:px-56 lg:pt-20 pt-24 max-w-[1800px] mx-auto">
					<h4 className="text-4xl md:text-5xl my-3">
						<span className="fancy-font highlighted leading-normal">{post?.title}</span>
					</h4>
					<hr />
					{post && <div className="content" dangerouslySetInnerHTML={{ __html: post.content }} />}
				</div>
			</main>
			<Footer />
		</>
	)
}

export default Post
