import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useRef, useState } from 'react'
import EditorTopSection from './top-section'
import './humanizer.scss'

import {
	HumanizerIntensityLevel,
	LiteracyLevel,
	Purpose,
	editorAtom,
	editorDefaultValues,
	humanizerIntensityLevelDisplayNames,
	literacyLevelDisplayNames,
	purposeDisplayNames,
} from './constants'
import { useHumanizeText, useWordCount } from './hooks'
import Button from '../../Components/Buttons'
import { EditorContent, useEditor } from '@tiptap/react'
import Dropdown from '../../Components/Dropdown/Dropdown'
import UpgradeModals from '../../Components/Modals/Upgrade'
import { useCookies } from 'react-cookie'
import useIsPremium from '../../hooks/useIsPremium'
import useIsSubmit from '../../hooks/useIsSubmit'
import AIDetectionScore from './detection-score'
import { useLocation } from 'react-router-dom'

const HumanizerSection = () => {
	const [intensityMenuOpen, setIntensityMenuOpen] = useState(false)
	const [literacyMenuOpen, setLiteracyMenuOpen] = useState(false)
	const [purposeMenuOpen, setPurposeMenuOpen] = useState(false)
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)

	const [editorState, setEditorState] = useAtom(editorAtom)
	const wordCount = useWordCount(editorState.textInputValue)
	const humanize = useHumanizeText()
	const closeLiteracyOptions = useRef(null)
	const closeIntensityOptions = useRef(null)
	const closePurposeOptions = useRef(null)
	const [cookies] = useCookies(['humanizerCredits'])
	const isPremium = useIsPremium()
	const isSubmit = useIsSubmit()

	const accountModals = useMemo(
		() => (
			<>
				<UpgradeModals
					subscriptionModalOpen={subscriptionModalOpen}
					loginModalOpen={loginModalOpen}
					setSubscriptionModalOpen={(open: boolean) => {
						setSubscriptionModalOpen(open)
					}}
					setLoginModalOpen={(open: boolean) => {
						setLoginModalOpen(open)
					}}
					premiumPath={true}
					noMoreCredits
				/>
			</>
		),
		[subscriptionModalOpen, loginModalOpen]
	)

	const literacyOptions = Object.values(LiteracyLevel).map((level) => ({
		name: literacyLevelDisplayNames[level],
		key: level,
	}))

	const humanizerIntensityOptions = Object.values(HumanizerIntensityLevel).map((level) => ({
		name: humanizerIntensityLevelDisplayNames[level],
		key: level,
	}))

	const humanizerPurposeOptions = Object.values(Purpose).map((opt) => ({
		name: purposeDisplayNames[opt],
		key: opt,
	}))

	const literacyOptionsMenu = (
		<>
			{literacyOptions?.map((option) => (
				<div
					key={option.key}
					onClick={() => {
						setEditorState({ ...editorState, literacyLevel: option.key })
						setLiteracyMenuOpen(false)
					}}
					className="px-2 py-1 cursor-pointer hover:bg-gray-100"
				>
					<div className="px-2">{option.name}</div>
				</div>
			))}
		</>
	)

	const humanizerIntensityOptionsMenu = (
		<>
			{humanizerIntensityOptions?.map((option) => (
				<div
					key={option.key}
					onClick={() => {
						setEditorState({ ...editorState, humanizerIntensityLevel: option.key })
						setIntensityMenuOpen(false)
					}}
					className="px-2 py-1 cursor-pointer hover:bg-gray-100"
				>
					<div className="px-2">{option.name}</div>
				</div>
			))}
		</>
	)

	const humanizerPurposeOptionsMenu = (
		<div className="max-h-36 overflow-y-auto">
			{humanizerPurposeOptions?.map((option) => (
				<div
					key={option.key}
					onClick={() => {
						setEditorState({ ...editorState, purpose: option.key })
						setPurposeMenuOpen(false)
					}}
					className="px-2 py-1 cursor-pointer hover:bg-gray-100"
				>
					<div className="px-2">{option.name}</div>
				</div>
			))}
		</div>
	)

	const _humanize = () => {
		if (isPremium || (parseInt(cookies.humanizerCredits ?? '0') ?? 0) + wordCount < 300) {
			humanize(false)
		} else {
			setSubscriptionModalOpen(true)
		}
	}

	return (
		<>
			{accountModals}
			<div
				className={`flex justify-end w-full ${
					isSubmit ? 'bg-tertiary' : 'bg-gray-900'
				} py-2 px-3 border border-t-0 border-gray-500 shadow-lg`}
			>
				<div className="w-full flex justify-end rounded-tl-none rounded-tr-none ">
					<div className="flex sm:flex-row flex-col justify-between w-full items-center gap-2">
						<div className="flex gap-4 items-center flex-wrap">
							<div className="relative">
								<div className="flex gap-4 md:gap-1">
									<div className="text-gray-200">Intensity:</div>
									<Button
										type="tertiary"
										btnRef={closeIntensityOptions}
										className={`sm:w-auto w-full text-black ${
											intensityMenuOpen ? (isSubmit ? 'bg-gray-200' : '') : 'bg-white hover:bg-gray-200'
										}`}
										onClick={() => {
											setIntensityMenuOpen(!intensityMenuOpen)
										}}
										emphasize={intensityMenuOpen && !isSubmit}
									>
										{humanizerIntensityLevelDisplayNames[editorState.humanizerIntensityLevel]}{' '}
									</Button>
								</div>
								<Dropdown
									open={intensityMenuOpen}
									setOpen={(val) => {
										setIntensityMenuOpen(val)
									}}
									body={humanizerIntensityOptionsMenu}
									closeButton={closeIntensityOptions}
									className="absolute top-8 right-0 bg-white border border-gray-300 shadow-md w-36 z-10"
								/>
							</div>
							<div className="relative">
								<div className="flex gap-4 md:gap-1">
									<div className="text-gray-200">Literacy:</div>
									<Button
										type="tertiary"
										btnRef={closeLiteracyOptions}
										className={`sm:w-auto w-full text-black ${
											literacyMenuOpen ? (isSubmit ? 'bg-gray-200' : '') : 'bg-white hover:bg-gray-200'
										}`}
										onClick={() => {
											setLiteracyMenuOpen(!literacyMenuOpen)
										}}
										emphasize={literacyMenuOpen && !isSubmit}
									>
										{literacyLevelDisplayNames[editorState.literacyLevel]}{' '}
									</Button>
								</div>
								<Dropdown
									open={literacyMenuOpen}
									setOpen={(val) => {
										setLiteracyMenuOpen(val)
									}}
									body={literacyOptionsMenu}
									closeButton={closeLiteracyOptions}
									className="absolute top-8 right-0 bg-white border border-gray-300 shadow-md w-36 z-10"
								/>
							</div>
							<div className="relative">
								<div className="flex gap-4 md:gap-1">
									<div className="text-gray-200">Purpose:</div>
									<Button
										type="tertiary"
										btnRef={closePurposeOptions}
										className={`sm:w-auto w-full text-black ${
											purposeMenuOpen ? (isSubmit ? 'bg-gray-200' : '') : 'bg-white hover:bg-gray-200'
										}`}
										onClick={() => {
											setPurposeMenuOpen(!purposeMenuOpen)
										}}
										emphasize={purposeMenuOpen && !isSubmit}
									>
										{purposeDisplayNames[editorState.purpose]}
									</Button>
								</div>
								<Dropdown
									open={purposeMenuOpen}
									setOpen={(val) => {
										setPurposeMenuOpen(val)
									}}
									body={humanizerPurposeOptionsMenu}
									closeButton={closePurposeOptions}
									className="absolute top-8 right-0 bg-white border border-gray-300 shadow-md w-36 z-10"
								/>
							</div>
						</div>
						<Button
							onClick={_humanize}
							emphasize={!isSubmit}
							type="tertiary"
							disabled={
								wordCount === 0 ||
								editorState.isLoadingHumanizeText ||
								wordCount > 3000 ||
								editorState.aiDetectionScoreLoading
							}
							className="sm:w-auto w-full bg-white text-black hover:bg-gray-200"
						>
							{editorState.isLoadingHumanizeText ? 'Humanizing...' : 'Humanize text'}
						</Button>
					</div>
				</div>
			</div>
		</>
	)
}

export default function Editor({ detector = false }: { detector?: boolean }) {
	const [editorState, setEditorState] = useAtom(editorAtom)
	const humanize = useHumanizeText()
	const wordCount = useWordCount(editorState.textInputValue)
	const location = useLocation()

	useEffect(() => {
		setEditorState({
			...editorState,
			aiDetectionScore: undefined,
			previousHumanization: undefined,
		})
	}, [editorState.textInputValue])

	const editor = useEditor(
		{
			extensions: [
				Document,
				Text,
				Paragraph,
				Placeholder.configure({
					placeholder: 'Paste your text here...',
				}),
			],
			onUpdate: ({ editor }) => {
				setEditorState({ ...editorState, textInputValue: editor.getText() })
			},
		},
		[]
	)

	useEffect(() => {
		if (!editor) return
		if (editorState.textInputValue !== editor?.getText()) {
			editor.commands.setContent(editorState.textInputValue, false, {
				preserveWhitespace: 'full',
			})
		}
	}, [editorState.textInputValue, editor])

	useEffect(() => {
		setEditorState(editorDefaultValues)
		editor?.commands.setContent(editorDefaultValues.textInputValue, false, {
			preserveWhitespace: 'full',
		})
	}, [location.pathname])

	return (
		<div className="md:w-2/3 w-full">
			{detector ? <AIDetectionScore detector /> : <EditorTopSection />}
			{detector && <div className={`oscillating-bar ${editorState.aiDetectionScoreLoading ? 'block' : 'hidden'}`} />}

			<div className={`bg-white relative h-96 overflow-y-auto border border-gray-500 border-b-0`}>
				<EditorContent
					id="humanizer"
					editor={editor}
					className="overflow-auto text-sm"
					placeholder="Paste your sample text here..."
				/>
			</div>
			<div className={`oscillating-bar ${editorState.isLoadingHumanizeText ? 'block' : 'hidden'}`} />
			{detector ? <EditorTopSection detector={detector} /> : <HumanizerSection />}
			{wordCount > 3000 && (
				<div className="flex items-center justify-end gap-3 mt-4 text-danger-dark text-sm">
					You have exceeded the maximum word limit of 3000 words.
				</div>
			)}
			<div
				className={
					editorState.previousHumanization && !editorState.isLoadingHumanizeText
						? 'opacity-100 pointer-events-auto transition-opacity duration-300'
						: 'opacity-0 pointer-events-none transition-opacity duration-300'
				}
			>
				<div className="flex items-center justify-end gap-3 mt-4">
					<div className="text-sm text-gray-800">Not satisfied with the result?</div>
					<Button
						type="secondary"
						onClick={() => {
							humanize(true)
						}}
						className="border border-gray-400 bg-white"
					>
						Retry for free.
					</Button>
				</div>
			</div>
		</div>
	)
}
