import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { useCookies } from 'react-cookie'
import { FEATURE_FLAGS } from './featureFlags'

const useIsSubmit = () => {
	const submitAIFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SUBMIT_AI)
	const [cookies] = useCookies(['isSubmit'])
	return cookies.isSubmit === 'true' || !!submitAIFF
}

export default useIsSubmit
