import { useFeatureFlagVariantKey } from 'posthog-js/react'

export const FEATURE_FLAGS = {
	WORD_COUNT: 'word-count-2',
	PHILIPPINES_PRICING: 'philippines-pricing',
	STEALTH_GPT: 'stealth-gpt',
	PADDLE: 'paddle',
	EMPHASIZE_AUTOCOMPLETE: 'emphasize-autocomplete',
	GRADER_V2: 'grader-v2',
	ASK_AI_PROMPT: 'ask-ai-prompt',
	HUMANIZER_DETECTOR_TOOLS: 'humanizer-detector-tools',
	LOWER_SUBTOPIC_MINIMUM: 'lower-subtopic-minimum',
	MULTIPLE_DRAFTS: 'multiple-drafts',
	PRIMARY_FONT_TOPIC: 'primary-font-topic',
	NAVBAR_TITLE: 'navbar-title',
	SUBMIT_AI: 'submit-ai',
}

export const usePricing = () => {
	const philippinesPricingFF = useFeatureFlagVariantKey(FEATURE_FLAGS.PHILIPPINES_PRICING) as 'control' | '40'

	const defaultPricing = {
		monthly: '9',
		yearly: '6',
		yearlyTotal: '72',
		discount: '33',
		stripePriceId: { yearly: 'price_1Q39I6ApflHFLqu6T9HPheD3', monthly: 'price_1PsqLqApflHFLqu6N1DpjqlU' },
	}
	const philippinesPricing = {
		monthly: {
			control: '400',
			'40': '300',
		},
		yearly: {
			control: '292',
			'40': '233',
		},
		yearlyTotal: {
			control: '3500',
			'40': '2800',
		},
		discount: {
			control: '27',
			'40': '22',
		},
		stripePriceId: {
			yearly: {
				control: 'price_1Pyd4cApflHFLqu6LnNTg4ST',
				'40': 'price_1Pyd4FApflHFLqu6rCWfWwRx',
			},
			monthly: {
				control: 'price_1Pyd2OApflHFLqu6dCeBSFOI',
				'40': 'price_1Pyd15ApflHFLqu6ekx5wHMk',
			},
		},
	}

	if (['control', '40'].includes(philippinesPricingFF as string)) {
		return {
			monthly: philippinesPricing.monthly[philippinesPricingFF] as string,
			yearly: philippinesPricing.yearly[philippinesPricingFF] as string | null,
			yearlyTotal: philippinesPricing.yearlyTotal[philippinesPricingFF] as string | null,
			discount: philippinesPricing.discount[philippinesPricingFF] as string | null,
			stripePriceId: {
				yearly: philippinesPricing.stripePriceId.yearly[philippinesPricingFF] as string | null,
				monthly: philippinesPricing.stripePriceId.monthly[philippinesPricingFF] as string,
			},
		}
	}

	return defaultPricing
}

export const useFreeWordLimit = () => {
	const variant = useFeatureFlagVariantKey(FEATURE_FLAGS.WORD_COUNT)

	const variantToLimit = {
		control: 250,
		'150': 150,
		'350': 350,
	}

	// @ts-ignore
	return variantToLimit[variant ?? 'control'] as 150 | 250 | 350
}
