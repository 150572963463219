import { createSlice } from '@reduxjs/toolkit'
import { SystemState } from './types'
import { initialSystemState } from './constants'

export const systemSlice = createSlice({
	name: 'system',
	initialState: initialSystemState as SystemState,
	reducers: {
		setShowNav: (state, action) => {
			state.showNav = action.payload.value
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
		setShowHelpBanner: (state, action) => {
			state.showHelpBanner = action.payload.value
		},
		setLastAggregatePageVisited: (state, action) => {
			state.lastAggregatePageVisited = action.payload.value
		},
		setUserLoading: (state, action) => {
			state.userLoading = action.payload.value
		},
		setUserPagesCache: (state, action) => {
			state.userPagesCache = action.payload
		},
		setUserPagesLoading: (state, action) => {
			state.userPagesLoading = action.payload.value
		},
		setJWToken: (state, action) => {
			state.tiptapJWToken = action.payload
		},
		setPaddle: (state, action) => {
			state.paddle = action.payload
    },
		setGraderModeOn: (state, action) => {
			state.graderModeOn = action.payload
		},
		setGraderCategory: (state, action) => {
			state.selectedGraderCategory = action.payload
		},
		setCategoryScoresLoading: (state, action) => {
			state.categoryScoresLoading = action.payload
		},
		setNumAcceptedSuggestions: (state, action) => {
			state.numAcceptedSuggestions = action.payload
		},
	},
})

export const {
	setShowNav,
	setShowHelpBanner,
	setLastAggregatePageVisited,
	setUserLoading,
	setUser,
	setUserPagesCache,
	setUserPagesLoading,
	setJWToken,
	setPaddle,
	setGraderModeOn,
	setGraderCategory,
	setCategoryScoresLoading,
	setNumAcceptedSuggestions,
} = systemSlice.actions
export const selectShowNav = (state: any) => state.system.showNav
export const selectShowHelpBanner = (state: any) => state.system.showHelpBanner
export const selectLastAggregatePageVisited = (state: any) => state.system.lastAggregatePageVisited
export const selectUserLoading = (state: any) => state.system.userLoading
export const selectUser = (state: any) => state.system.user
export const selectUserPagesCache = (state: any) => state.system.userPagesCache
export const selectUserPagesLoading = (state: any) => state.system.userPagesLoading
export const selectJWToken = (state: any) => state.system.tiptapJWToken
export const selectPaddle = (state: any) => state.system.paddle
export const selectGraderModeOn = (state: any) => state.system.graderModeOn
export const selectGraderCategory = (state: any) => state.system.selectedGraderCategory
export const selectCategoryScoresLoading = (state: any) => state.system.categoryScoresLoading
export const selectNumAcceptedSuggestions = (state: any) => state.system.numAcceptedSuggestions

export default systemSlice.reducer
