import { useSelector } from 'react-redux'
import { selectUserPagesCache } from '../../redux/systemSlice'
import { ExistingDoc } from '../UserDocs/UserDocs'
import TextareaAutosize from 'react-textarea-autosize'
import { useMemo, useState } from 'react'
import { CONSTANTS } from '../../constants'
import { Link } from 'react-router-dom'
import './_Docs.scss'
import Modal from './Modal'
import useIsSubmit from '../../hooks/useIsSubmit'

const ExistingDocsModal = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
	const [searchInput, setSearchInput] = useState('')
	const userPagesCache = useSelector(selectUserPagesCache)
	const isSubmit = useIsSubmit()

	const filteredDocs = useMemo(() => {
		if (!searchInput.length) {
			return userPagesCache.pages
		}
		return userPagesCache.pages.filter((page: ExistingDoc) => {
			return page?.title?.toLowerCase().includes(searchInput.toLowerCase())
		})
	}, [searchInput, userPagesCache.pages])

	return (
		<Modal open={isOpen} closeModal={closeModal} portalClassName="existingDocs">
			<div>
				<TextareaAutosize
					placeholder="Search documents..."
					id="search"
					autoFocus
					onChange={(e) => {
						setSearchInput(e.target.value)
					}}
					minRows={1}
					maxRows={2}
					value={searchInput}
					className="px-3 py-2"
				/>
				<div className="dividingBorder" />
				{filteredDocs.map((page: ExistingDoc, index: number) => {
					return (
						<Link
							className={`doc px-3 py-1 ${isSubmit ? 'hover:bg-gray-200' : 'hover:bg-secondary'}`}
							to={CONSTANTS.CREATE_PATH + '/' + page.template + '/' + btoa(page.id.toString())}
							key={index + 'doc'}
							onClick={closeModal}
						>
							{!!page?.title?.length ? page.title : 'Untitled'}
						</Link>
					)
				})}
			</div>
		</Modal>
	)
}

export default ExistingDocsModal
