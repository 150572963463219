import React, { useEffect, useMemo, useRef } from 'react'
import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react'
import { useSelector } from 'react-redux'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { useDocId } from '../../hooks/docID'
import { createPopper, Instance } from '@popperjs/core'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

const HoverMenuNodeView: React.FC<NodeViewProps> = ({ node, getPos, editor }) => {
	const docId = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const domRef = useRef<HTMLSpanElement>(null)
	const menuRef = useRef<HTMLDivElement>(null)
	const popperInstance = useRef<Instance | null>(null)

	function extractLastName(citationText: string) {
		// Remove the surrounding parentheses
		const withoutParentheses = citationText.slice(1, -1)

		// Split the string at the comma
		const parts = withoutParentheses.split(',')

		if (parts.length === 2) {
			// The first part is the last name(s)
			const names = parts[0].trim()
			return names
		} else {
			return null
		}
	}

	function extractLastNameFromReference(referenceAuthor: string) {
		const parts = referenceAuthor.split(' ')
		return parts[parts.length - 1]
	}

	const reference = useMemo(() => {
		const lastName = extractLastName(node.textContent)
		const simpleLastName = lastName?.split(' ')[0] ?? ''

		if (lastName) {
			return documentState.references.find((ref) => {
				const processedAuthorLastNames = ref.authors?.map(extractLastNameFromReference)
				return (
					ref.authors?.includes(lastName) ||
					ref.authors?.includes(simpleLastName) ||
					processedAuthorLastNames?.includes(lastName) ||
					processedAuthorLastNames?.includes(simpleLastName)
				)
			})
		}
		return null
	}, [documentState.references, node.attrs.citationText])

	useEffect(() => {
		const dom = domRef.current
		const menu = menuRef.current

		if (!dom || !menu) {
			return
		}

		popperInstance.current = createPopper(dom, menu, {
			placement: 'bottom-end',
			modifiers: [
				{
					name: 'preventOverflow',
					options: {
						boundary: 'viewport',
					},
				},
			],
		})

		document.body.appendChild(menu)

		let isHovering = false
		let hideTimeout: number | null = null

		const showMenu = () => {
			if (!reference) {
				return
			}

			if (hideTimeout) {
				clearTimeout(hideTimeout)
				hideTimeout = null
			}
			if (!isHovering) {
				isHovering = true
				menu.style.display = 'block'

				if (popperInstance.current) {
					popperInstance.current.update()
				}
			}
		}

		const hideMenu = () => {
			// @ts-ignore
			hideTimeout = setTimeout(() => {
				isHovering = false
				menu.style.display = 'none'
			}, 100) // Adjust delay as needed
		}

		// dom.addEventListener('mouseenter', showMenu)
		// dom.addEventListener('mouseleave', hideMenu)
		// menu.addEventListener('mouseenter', showMenu)
		// menu.addEventListener('mouseleave', hideMenu)

		// Cleanup
		return () => {
			// dom.removeEventListener('mouseenter', showMenu)
			// dom.removeEventListener('mouseleave', hideMenu)
			// menu.removeEventListener('mouseenter', showMenu)
			// menu.removeEventListener('mouseleave', hideMenu)

			if (hideTimeout) {
				clearTimeout(hideTimeout)
			}

			if (popperInstance.current) {
				popperInstance.current.destroy()
				popperInstance.current = null
			}

			menu.remove()
		}
	}, [reference])

	const filteredAuthors = reference?.authors?.filter((author) => author !== '')

	return (
		<NodeViewWrapper ref={domRef} data-hover-menu="true" as="span">
			<NodeViewContent as="span" />
			<div
				ref={menuRef}
				className="text-primary rounded shadow z-50 py-3 px-5 border bg-white hidden md:w-96 w-80 overflow-hidden"
			>
				{reference && documentState?.userWriterSelections?.refStyle && (
					<div className="text-sm max-w-64 font-semibold">{reference.title}</div>
				)}
				<div className="text-gray-700 text-sm">
					{filteredAuthors?.map((author, idx) => author + (idx === (filteredAuthors?.length ?? 0) - 1 ? '' : ', '))}
				</div>
				<div className="text-gray-700 text-sm">
					{reference?.publisher ? reference?.publisher + ',' : ''}{' '}
					{reference?.publishedDate ? new Date(reference?.publishedDate).getFullYear() : ''}
				</div>
				<div>
					<a
						href={reference?.url}
						target="_blank"
						rel="noreferrer"
						className="text-sm text-link whitespace-nowrap overflow-hidden overflow-ellipsis w-full inline-block"
					>
						{reference?.url}
					</a>
				</div>
			</div>
		</NodeViewWrapper>
	)
}

export default HoverMenuNodeView
